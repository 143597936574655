import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { RootState } from '../../../redux/store/store';

interface Props extends RouteProps {
  component: any;
  newProject?: boolean;
}

const PrivateRoute: FC<Props> = ({ component: Component, newProject: newProjectProp, ...rest }) => {
  const { authenticated } = useSelector((state: RootState) => state.auth);

  return (
    <Route {...rest} render={props => authenticated ? <Component newProject={newProjectProp} {...props} /> : <Redirect to="/signin" />} />
  );
}

export default PrivateRoute;
