import React, { FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap"
import { PrimaryButton, Dropdown, FontIcon, IDropdownOption } from "@fluentui/react";
import { getPrimaryGreenTheme } from "src/common/themes";
import { RinktMLClient } from "src/services/rinktService";

interface IShareProjectProps {
    multiSelect?: boolean;
}

const theme = getPrimaryGreenTheme();

const ShareProject = ({ multiSelect = false }: IShareProjectProps) => {
    const currentProject = useSelector((state) => state.currentProject);
    const recentProjects = useSelector((state) => state.recentProjects);
    const impersonation = useSelector((state) => state.impersonation);
    const auth = useSelector((state) => state.auth);

    const [ selectedProjectsIds, setSelectedProjectsIds ] = useState<string[]>(multiSelect ? [] : [ currentProject.id ]);
    const [ emails, setEmails ] = useState<string>('');

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        try {
            const rinktClient = new RinktMLClient(auth.idToken, impersonation);

            selectedProjectsIds.forEach((projectId) => (
                emails.split(',').forEach(async(email)=> {
                    const successMessage = `Successfully shared project${selectedProjectsIds.length > 1 ? 's' : ''} with ${email}`;
                    const errorMessage = `Failed to share project${selectedProjectsIds.length > 1 ? 's' : ''} with ${email}`;

                    try {
                        await rinktClient.shareProject(projectId, email.trim());

                        if (projectId.length === 9) {
                            toast.success(`${successMessage}. The page will reload!`);
                            window.location.reload();
                        } else {
                            toast.success(successMessage);
                        }
                    } catch (err) {
                        console.log(err);
                        toast.error(errorMessage);
                    }
                })
            ));
        } catch (err) {
            console.log(err);
            toast.error("There was an error processing your request");
        }
    };

    const handleProjectSelect = (event: FormEvent<HTMLDivElement>, option: IDropdownOption) => {
        if (option.selected) {
            setSelectedProjectsIds((selectedProjectsIds) => ([
                ...selectedProjectsIds,
                option.key as string
            ]));
        } else {
            setSelectedProjectsIds((selectedProjects) => selectedProjects.filter(key => key !== option.key))
        }
    }

    return (
        <>
            <h3 className="flex-center">
                <FontIcon iconName="Share" />
                <span className="px-2">Share Project</span>
            </h3>
            <div className="m-3">
                <Form onSubmit={handleSubmit}>
                    <div className="form-fields">
                        {multiSelect && (
                            <Form.Group id="project">
                                <Form.Label>Share selected projects:</Form.Label>
                                <Dropdown
                                    placeholder="Select projects"
                                    onChange={handleProjectSelect}
                                    options={recentProjects.map(p => ({ key: p.id, value: p.id, text: p.name }))}
                                    multiSelect
                                />
                            </Form.Group>
                        )}
                        <Form.Group id="email">
                            <Form.Label>{multiSelect ? 'To user(s)' : 'Share project with user(s)'}</Form.Label>
                            <Form.Control
                                value={emails}
                                required
                                title='Comma separated list of emails'
                                onChange={(e) => setEmails(e.currentTarget.value)}
                                placeholder="Enter email(s)" />
                        </Form.Group>
                    </div>
                    <PrimaryButton
                        theme={theme}
                        className="mr-2"
                        type="submit"
                    >
                        Share
                    </PrimaryButton>
                </Form>
            </div>
        </>
    );
}

export default ShareProject;
