import React, { useState } from "react";
import { FontIcon } from "@fluentui/react";
import ConditionalNavLink from "../common/conditionalNavLink/conditionalNavLink";
import { strings } from "../../../common/strings";
import "./sidebar.scss";

interface SidebarProps {
    projectId: string;
}

export function Sidebar({ projectId }: SidebarProps) {
    const [ isExpanded, setExpanded ] = useState(true);
    const toggleExpanded = () => setExpanded((expanded) => !expanded);

    const items = [
        {
            title: strings.homePage.title,
            to: '/dashboard',
            icon: 'Home',
            exact: true,
            disabled: false,
        },
        {
            title: strings.tags.editor,
            to: `/projects/${projectId}/edit`,
            icon: 'Tag',
            exact: false,
            disabled: !projectId,
        },
        {
            title: strings.train.title,
            to: `/projects/${projectId}/train`,
            icon: 'MachineLearning',
            exact: false,
            disabled: !projectId,
        },
        {
            title: strings.modelCompose.title,
            to: `/projects/${projectId}/modelcompose`,
            icon: 'Merge',
            exact: false,
            disabled: !projectId,
        },
        {
            title: strings.predict.title,
            to: `/projects/${projectId}/predict`,
            icon: 'Insights',
            exact: false,
            disabled: !projectId,
        },
        {
            title: strings.projectSettings.title,
            to: `/projects/${projectId}/settings`,
            icon: 'DocumentManagement',
            exact: false,
            disabled: !projectId,
        },
        {
            title: 'Utilities', // TODO: localize
            to: `/projects/${projectId}/sharing`,
            icon: 'Share',
            exact: false,
            disabled: !projectId,
        },
        {
            title: 'Settings', // TODO: localize
            to: `/settings`,
            icon: 'Settings',
            exact: false,
            disabled: false,
        },
    ];

    return (
        <div className="bg-lighter-2 app-sidebar">
            <ul>
                {items.map((item) => (
                    <li key={item.title}>
                        <ConditionalNavLink
                            disabled={item.disabled}
                            exact={item.exact}
                            to={item.to}
                            {...!isExpanded && {
                                title: item.title
                            }}
                        >
                            <FontIcon iconName={item.icon} />
                            {isExpanded && (
                                <span className="label">{item.title}</span>
                            )}
                        </ConditionalNavLink>
                    </li>
                ))}
            </ul>
            <div
                className="toggle"
                onClick={toggleExpanded}
                title={isExpanded ? "Collapse sidebar" : "Expand sidebar"}
            >
                <FontIcon iconName={isExpanded ? "ChevronLeft" : "ChevronRight"} />
            </div>
        </div>
    );
}
