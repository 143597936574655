import { AuthAction, AuthState, ActionTypes } from '../actions/actionTypes';

const initialState: AuthState = {
    user: null,
    authenticated: false,
    loading: false,
    error: '',
    needVerification: false,
    success: '',
    idToken: '',
}

export const reducer = (state = initialState, action: AuthAction) => {
    switch (action.type) {
        case ActionTypes.SET_USER:
            return {
                ...state,
                user: action.payload,
                authenticated: true
            }
        case ActionTypes.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case ActionTypes.SIGN_OUT:
            return {
                ...state,
                user: null,
                authenticated: false,
                loading: false
            }
        case ActionTypes.SET_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case ActionTypes.NEED_VERIFICATION:
            return {
                ...state,
                needVerification: true
            }
        case ActionTypes.SET_SUCCESS:
            return {
                ...state,
                success: action.payload
            }
        case ActionTypes.SET_ID_TOKEN:
            return {
                ...state,
                idToken: action.payload
            }
        default:
            return state;
    }
}
