import React, { FormEvent } from 'react';
import { Form } from "react-bootstrap"
import { FontIcon, PrimaryButton } from "@fluentui/react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getPrimaryGreenTheme } from "../../../../common/themes";
import { IImpersonatedUser, IImpersonation, IApplicationState } from "../../../../models/applicationState";
import IImpersonationActions, * as impersonationActions from '../../../../redux/actions/impersonationActions';
import { toast } from "react-toastify";
import firebase from '../../../../config/firebase';

function mapStateToProps(state: IApplicationState) {
    return {
        recentProjects: state.recentProjects,
        impersonation: state.impersonation,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        impersonationActions: bindActionCreators(impersonationActions, dispatch),
    };
}

interface IImpersonationComponentProps {
    impersonation?: IImpersonation,
    impersonationActions?: IImpersonationActions;
}

interface IImpersonationComponentState {
    impersonatedUser?: IImpersonatedUser;
    isAdmin: boolean;
    freeTextEmail?: string;
}

@connect(mapStateToProps, mapDispatchToProps)
export default class ImpersonationComponent extends React.Component<IImpersonationComponentProps, IImpersonationComponentState> {
    public state: IImpersonationComponentState = {
        impersonatedUser: null,
        isAdmin: false
    }

    public componentDidMount(){
        firebase.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
            this.setState({ ...this.state, isAdmin: !!idTokenResult.claims.admin })
        });
    }

    private setImpersonationUser = (e: any) => {
        const impersonate = this.props.impersonation.impersonators.filter((el) => el.uid === e.target.value);
        if (impersonate) {
            this.setState({ ...this.state, impersonatedUser: impersonate[0] })
        }
    }

    private setFreeTextImpersonationUser = (e: any) => {
        this.setState({ ...this.state, freeTextEmail: e.target.value})
    }

    private impersonationHandler = async (e: FormEvent) => {
        e.preventDefault();

        let user = this.state.impersonatedUser;
        if(!user && this.state.freeTextEmail && this.state.freeTextEmail !== '') {
            const db = firebase.firestore();
            const documentData = await db.collection('users').where('email', '==', this.state.freeTextEmail).limit(1).get();
            const users = documentData.docs.map(d => d.data());
            if (users.length === 0){
                toast.error(`Could not find user for email ${this.state.freeTextEmail}`);
            } else {
                user = {uid: users[0].uid, email: users[0].email};
                await this.impersonateUser(user);
            }
        }
        else {
            await this.impersonateUser(user);
        }
    }

    private async impersonateUser(user: IImpersonatedUser) {
        try {
            console.log("Impersonate as  " + user)
            await this.props.impersonationActions.impersonateAs(user);
            toast.success(`Successfully impersonated user ${user?.email}`)
        } catch (err) {
            toast.error(`Failed to impersonate user ${user.email}`)
        }
    }

    public render() {
        let impersonators = this.props.impersonation.impersonators;
        impersonators = [...impersonators, { "uid": "", "email": "" }];
        return (
            <div>
                <h3 className="mt-5 flex-center">
                    <FontIcon iconName="User" />
                    <span className="px-2">Impersonate user</span>
                </h3>
                <div className="m-3">
                    <Form onSubmit={(e) => this.impersonationHandler(e)}>
                        <div className="form-fields">
                            <Form.Group id="impersonateUser">
                                <Form.Label>Impersonation users:</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={this.state.impersonatedUser ? this.state.impersonatedUser.uid : ""}
                                    onChange={(e) => this.setImpersonationUser(e)}>
                                    {impersonators.map((p, ix) => {
                                        return (<option value={p.uid} key={p.uid}>{p.email}</option>)
                                    })}
                                </Form.Control>

                                {
                                    this.state.isAdmin && <Form.Control className="mt-3"
                                        placeholder="Email to impersonate as admin"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => this.setFreeTextImpersonationUser(e)}
                                    />
                                }
                            </Form.Group>
                        </div>
                        <PrimaryButton
                            theme={getPrimaryGreenTheme()}
                            className="mr-2"
                            type="submit">
                            Impersonate
                        </PrimaryButton>
                    </Form>
                </div>
            </div>
        )
    }
}
