import { createPayloadAction, IPayloadAction } from "./actionCreators";
import { Dispatch } from "redux";
import { IImpersonatedUser } from "../../models/applicationState";
import { ActionTypes } from "./actionTypes";
import {
    IApplicationState,
} from "../../models/applicationState";

export default interface IImpersonationActions {
    loadImpersonators(): Promise<IImpersonatedUser[]>;
    setImpersonators(users: IImpersonatedUser[]): Promise<void>;
    impersonateAs(user: IImpersonatedUser): Promise<void>;
}

export function loadImpersonators():
    (dispatch: Dispatch, getState: () => IApplicationState) => Promise<IImpersonatedUser[]> {
    return async (dispatch: Dispatch, getState: () => IApplicationState) => {
        const loadedImpersonators = [{"email": "abc@abc.com", "uid": "abc"}];
        dispatch(loadImpersonatorsAction(loadedImpersonators));
        return loadedImpersonators
    };
}

export function setImpersonators(users: IImpersonatedUser[])
    : (dispatch: Dispatch) => Promise<void> {
    return async (dispatch: Dispatch) => {
        dispatch(setImpersonatorsAction(users));
    }
}

export function impersonateAs(user: IImpersonatedUser)
    : (dispatch: Dispatch) => Promise<void> {
    return async (dispatch: Dispatch) => {
        dispatch(impersonateAsAction(user));
    }
}

export interface ILoadAllowedImpersonations extends IPayloadAction<string, IImpersonatedUser[]> {
    type: ActionTypes.LOAD_ALLOWED_IMPERSONATION;
}

export interface ISetAllowedImpersonations extends IPayloadAction<string, IImpersonatedUser[]> {
    type: ActionTypes.SET_IMPERSONATORS;
}

export interface IImpersonateAs extends IPayloadAction<string, IImpersonatedUser> {
    type: ActionTypes.SET_IMPERSONATE_AS;
}

export const loadImpersonatorsAction = createPayloadAction<ILoadAllowedImpersonations>(ActionTypes.LOAD_ALLOWED_IMPERSONATION);
export const setImpersonatorsAction = createPayloadAction<ISetAllowedImpersonations>(ActionTypes.SET_IMPERSONATORS);
export const impersonateAsAction = createPayloadAction<IImpersonateAs>(ActionTypes.SET_IMPERSONATE_AS);
