import React, { FormEvent, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap"
import { toast } from "react-toastify";
import { FontIcon, Dropdown, IDropdownOption, PrimaryButton } from "@fluentui/react";
import { getPrimaryGreenTheme } from "src/common/themes";
import { RinktMLClient } from "src/services/rinktService";
import firebase from "src/config/firebase";

interface ICopyProjectProps {
    multiSelect?: boolean;
}

const emptyUserOption = { uid: "", email: "" };
const theme = getPrimaryGreenTheme();

const CopyProject = ({ multiSelect = false }: ICopyProjectProps) => {
    const recentProjects = useSelector((state) => state.recentProjects);
    const impersonation = useSelector((state) => state.impersonation);
    const auth = useSelector((state) => state.auth);

    const [ selectedProjectsIds, setSelectedProjectsIds ] = useState<string[]>([]);
    const [ selectedUserId, setSelectedUserId ] = useState<string>('');
    const [ email, setEmail ] = useState<string>('');
    const [ isAdmin, setIsAdmin ] = useState<boolean>(false);

    const transferProjects = async(userId = selectedUserId) => {
        try {
            const rinktClient = new RinktMLClient(auth.idToken, impersonation);

            await rinktClient.transferProjectsTo(selectedProjectsIds, userId);

            toast.success("Successfully transferred projects");
        } catch (err) {
            console.log(err);
            toast.error("There was an error processing your request")
        }
    };

    const handleSubmit = async(event: FormEvent) => {
        event.preventDefault();

        toast.success("Copying. Please wait...");

        if (selectedUserId) {
            return transferProjects();
        }

        if (email) {
            const db = firebase.firestore();
            const documentData = await db.collection('users')
                .where('email', '==', email)
                .limit(1)
                .get();

            const users = documentData.docs.map(d => d.data());

            if (!users.length) {
                return toast.error(`Could not find user for email ${email}`);
            }

            const { uid } = users[0];

            return transferProjects(uid);
        }
    };

    const handleProjectSelect = (event: FormEvent<HTMLDivElement>, option: IDropdownOption) => {
        if (option.selected) {
            setSelectedProjectsIds((selectedProjectsIds) => ([
                ...selectedProjectsIds,
                option.key as string
            ]));
        } else {
            setSelectedProjectsIds((selectedProjects) => selectedProjects.filter(key => key !== option.key))
        }
    };

    useEffect(() => {
        firebase.auth().currentUser.getIdTokenResult().then(({ claims }) => {
            if (claims.admin) {
                setIsAdmin(true);
            }
        });
    }, []);

    return (
        <>
            <h3 className="flex-center">
                <FontIcon iconName="Copy" />
                <span className="px-2">Copy Project</span>
            </h3>
            <div className="m-3">
                <Form onSubmit={handleSubmit}>
                    <div className="form-fields">
                        <Form.Group id="project">
                            <Form.Label>Copy selected projects:</Form.Label>
                            <Dropdown
                                placeholder="Select projects"
                                multiSelect={multiSelect}
                                onChange={handleProjectSelect}
                                options={recentProjects.map(p => ({ key: p.id, value: p.id, text: p.name }))}
                            />
                        </Form.Group>
                        <Form.Group id="receiver">
                            <Form.Label>To user:</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedUserId}
                                onChange={(event) => setSelectedUserId(event.target.value)}
                            >
                                {[ ...impersonation.impersonators, emptyUserOption ].map(({ uid, email }) => (
                                    <option key={uid} value={uid}>
                                        {email}
                                    </option>
                                ))}
                            </Form.Control>
                            {isAdmin && (
                                <Form.Control
                                    className="mt-3"
                                    placeholder="Email of user to copy project to"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            )}
                        </Form.Group>
                    </div>
                    <PrimaryButton
                        theme={theme}
                        className="mr-2"
                        type="submit"
                    >
                        Copy
                    </PrimaryButton>
                </Form>
            </div>
        </>
    );
};

export default CopyProject;
