import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

interface ConditionalNavLinkProps {
    to: string;
    title: string;
    disabled?: boolean;
    exact?: boolean;
    children: ReactNode;
}

export default function ConditionalNavLink({ to, title, disabled = false, exact = false, children }: ConditionalNavLinkProps) {
    if (disabled) {
        return (
            <span
                className="disabled"
                title={title}
            >
                {children}
            </span>
        );
    }

    return (
        <NavLink
            to={to}
            title={title}
            exact={exact}
            role="button"
        >
            {children}
        </NavLink>
    );
}
