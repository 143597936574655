import { ThunkAction } from 'redux-thunk';

import { ActionTypes, AuthAction, User, SignInData } from './actionTypes';
import { RootState } from '../store/store';
import firebase from '../../config/firebase';

export default interface IAuthActions {
    getUserById(id: string): Promise<void>;
    setLoading(value: boolean): void;
    signin(data: SignInData): Promise<void>;
    signout(): Promise<void>;
    setError(msg: string): void;
    setNeedVerification(): void;
    setSuccess(msg: string): void;
    setIdToken(idToken: string): void;
}

// Get user by id
export const getUserById = (id: string): ThunkAction<void, RootState, null, AuthAction> => {
    return async dispatch => {
        try {
            const user = await firebase.firestore().collection('users').doc(id).get();
            if (user.exists) {
                const userData = user.data() as User;
                dispatch({
                    type: ActionTypes.SET_USER,
                    payload: userData
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
}

// Set loading
export const setLoading = (value: boolean): ThunkAction<void, RootState, null, AuthAction> => {
    return dispatch => {
        dispatch({
            type: ActionTypes.SET_LOADING,
            payload: value
        });
    }
}

// Log in
export const signin = (data: SignInData, onError: () => void): ThunkAction<void, RootState, null, AuthAction> => {
    return async dispatch => {
        try {
            await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
        } catch (err) {
            console.log(err);
            onError();
            dispatch(setError(err.message));
        }
    }
}

// Log out
export const signout = (): ThunkAction<void, RootState, null, AuthAction> => {
    return async dispatch => {
        try {
            dispatch(setLoading(true));
            await firebase.auth().signOut();
            dispatch({
                type: ActionTypes.SIGN_OUT
            });
        } catch (err) {
            console.log(err);
            dispatch(setLoading(false));
        }
    }
}

// Set error
export const setError = (msg: string): ThunkAction<void, RootState, null, AuthAction> => {
    return dispatch => {
        dispatch({
            type: ActionTypes.SET_ERROR,
            payload: msg
        });
    }
}

// Set need verification
export const setNeedVerification = (): ThunkAction<void, RootState, null, AuthAction> => {
    return dispatch => {
        dispatch({
            type: ActionTypes.NEED_VERIFICATION
        });
    }
}

// Set success
export const setSuccess = (msg: string): ThunkAction<void, RootState, null, AuthAction> => {
    return dispatch => {
        dispatch({
            type: ActionTypes.SET_SUCCESS,
            payload: msg
        });
    }
}

// Set id token
export const setIdToken = (idToken: string): ThunkAction<void, RootState, null, AuthAction> => {
    return dispatch => {
        dispatch({
            type: ActionTypes.SET_ID_TOKEN,
            payload: idToken
        });
    }
}
