import React, { MouseEvent } from "react";
import { FontIcon, IconButton } from "@fluentui/react";
import { strings } from "src/common/strings";
import type { IProject } from "src/models/applicationState";
import "src/App.scss";

interface ProjectListItemProps {
    item: IProject;
    onClick(event: MouseEvent<HTMLDivElement>): void;
    onDelete(event: MouseEvent<HTMLDivElement>): void;
}

export default function ProjectListItem({ item: project, onClick, onDelete }: ProjectListItemProps) {
    return (
        <li className="project-item">
            <div
                className="condensed-list-item"
                onClick={onClick}
                aria-label={`${project.name} project`}
            >
                <FontIcon iconName="OpenFolderHorizontal" />
                <span className="px-2 ms-Fabric" style={{color: "inherit"}}>{project.name}</span>
                <IconButton className="float-right app-delete-button"
                    title={strings.common.delete}
                    onClick={onDelete}
                >
                    <FontIcon iconName="Delete" className="app-delete-icon" />
                </IconButton>
            </div>
        </li>
    );
}
