import _ from "lodash";
import axios from "axios";
import { constants } from "../common/constants";
import { AppError, ErrorCode, IProject, IImpersonation } from "../models/applicationState";
import ServiceHelper from "./serviceHelper";

export class RinktBlob {
    public name: string
    public blobBody?: string

    constructor(name: string) {
        this.name = name;
    }
}


export class RinktMLClient {
    private token: string;
    private public_url: string;
    private impersonation: IImpersonation;

    constructor(token: string, impersonation: IImpersonation) {
        this.token = token;
        this.impersonation = impersonation;
        // this.public_url = "http://localhost:8000";
        // this.public_url = "http://172.23.216.144:8000";
        // this.public_url = "https://staging-rinkt-ml-4prvxrauva-ew.a.run.app";
        this.public_url = "https://mlapi.rinkt.com" // if we disable this, the proxy defined in package.json
        // is going to be used overcoming the annoyance with CORS
    }

    public listBlobsFlat(options: any) {
        let rinkt_client = this
        return {

            [Symbol.asyncIterator]: async function* asyncGenerator() {
                const response = await axios.post(
                    `${rinkt_client.public_url}/list`,
                    { "prefix": options.prefix }, rinkt_client.getHeaders());

                //const response = await axios.get<Blob>(asset.path, config);
                if (response.status !== 200) {
                    throw new Error("Error listing resources");
                }
                const data: RinktBlob[] = await response.data;

                for (const el of data) {
                    yield el;
                }
            }
        }
    }

    public async download(name: string) {
        return await axios.get(this.getBlobDownloadUrl(name), this.getHeaders())
    }

    public getBlobDownloadUrl(name: string) {
        return `${this.public_url}/download/${name}`;
    }

    public getBlobUploadUrl() {
        return `${this.public_url}/upload`;
    }
    public async write(name: string, content: string | Buffer | File, length: number, projectId: string) {
        var formData = new FormData();
        formData.append("file", new Blob([content]), name)
        formData.append("project_id", projectId)
        return await axios.post(
            this.getBlobUploadUrl(),
            formData,
            this.getHeaders({ 'Content-Type': 'multipart/form-data' }))
    }

    public async getProjects() {
        return await axios.get(
            `${this.public_url}/initial_state`,
            this.getHeaders());
    }

    public async getProject(project: IProject) {
        return await axios.get(
            `${this.public_url}/project/${project.id}/${project.name}`,
            this.getHeaders())
    }

    public async saveProject(project: IProject) {
        return await axios.post(
            `${this.public_url}/project/${project.id}`,
            { "data": JSON.stringify(project) },
            this.getHeaders()
        )
    }

    public async deleteProject(project_id) {
        return await axios.delete(
            `${this.public_url}/project/${project_id}`,
            this.getHeaders())
    }

    public async deleteFile(project_id, file_name) {
        return await axios.delete(
            `${this.public_url}/project/${project_id}/${file_name}`,
            this.getHeaders())
    }

    public async exists(path: string) {
        return false
    }

    public async existsInProject(path: string, project_id: string): Promise<boolean> {
        return await axios.get(
            `${this.public_url}/project/${project_id}/exists/${path}`,
            this.getHeaders()).then((r) => {
                return r.data as boolean;
            })
    }

    public async analyze(url: string) {
        return await axios.post(
            `${this.public_url}/analyze`,
            { "url": url },
            this.getHeaders());
    }

    public async train(model: any, project_id: string) {
        return await axios.post(
            `${this.public_url}/train_model/${project_id}`,
            model,
            this.getHeaders({ "Content-Type": "application/json" }));
    }

    public async submitPredictionUrl(file_name: string, project_id: string) {
        const endpointURL = `${this.public_url}/submit_prediction_url/${project_id}`
        const headers = this.getHeaders({ "Content-Type": "application/json" });
        const body = { file_name: file_name };

        try {
            const response = await ServiceHelper.postWithAutoRetry(endpointURL, body, headers);
            const operationLocation = response.headers["operation-location"];

            return this.poll(() =>
                ServiceHelper.getWithAutoRetry(operationLocation, headers),
                120000,
                500);


        } catch (err) {
            if (err.response?.status === 404) {
                throw new AppError(
                    ErrorCode.RequestSendError,
                    "There was an error processing your request"
                );
            } else {
                ServiceHelper.handleServiceError({ ...err, endpoint: endpointURL });
            }
        }
    }
    private poll = (func, timeout, interval): Promise<any> => {
        const endTime = Number(new Date()) + (timeout || 10000);
        interval = interval || 100;

        const checkSucceeded = (resolve, reject) => {
            const ajax = func();
            ajax.then((response) => {
                if (response.data.status.toLowerCase() === constants.statusCodeSucceeded) {
                    resolve(response.data);
                    // prediction response from API
                } else if (response.data.status.toLowerCase() === constants.statusCodeFailed) {
                    reject(_.get(
                        response,
                        "data.analyzeResult.errors[0]",
                        "Generic error during prediction"));
                } else if (Number(new Date()) < endTime) {
                    // If the request isn't succeeded and the timeout hasn't elapsed, go again
                    setTimeout(checkSucceeded, interval, resolve, reject);
                } else {
                    // Didn't succeeded after too much time, reject
                    reject("Timed out, please try other file.");
                }
            });
        };

        return new Promise(checkSucceeded);
    }

    public async submitPrediction(file: File, model_id: string, project_id: string) {
        var formData = new FormData();
        formData.append("file", file);
        return await axios.post(
            `${this.public_url}/submit_prediction/${project_id}?model_id=${model_id}`,
            formData,
            this.getHeaders({ "Content-Type": "application/json" }));
    }

    getHeaders(additional_headers: any = null) {
        let headers = { "Authorization": this.token }
        if (this.impersonation && this.impersonation.impersonatedUser) {
            headers['Impersonation'] = this.impersonation.impersonatedUser.uid
        }
        additional_headers = additional_headers ? additional_headers : {}
        return { headers: { ...headers, ...additional_headers } }
    }

    public async getAllModels(projectId: string) {
        return await axios.get(`${this.public_url}/model_list/${projectId}`, this.getHeaders())
    }

    public async getLatestModels(projectId: string) {
        return await axios.get(`${this.public_url}/latest_model_list/${projectId}`, this.getHeaders())
    }


    public async getModel(model_id: string, project_id: string) {
        return await axios.get(
            `${this.public_url}/models/${model_id}/${project_id}`, this.getHeaders())
    }

    public async composeModels(models: any) {
        return await axios.post(
            `${this.public_url}/compose`,
            models,
            this.getHeaders({ "Content-Type": "application/json" }));
    }

    public async deleteModel(model_id: any, project_id: string) {
        return await axios.delete(
            `${this.public_url}/models/${model_id}/${project_id}`,
            this.getHeaders())
    }

    public async shareProject(project_id: string, email: string) {
        return await axios.post(
            `${this.public_url}/share_project`,
            {
                project_id: project_id,
                email: email
            }, this.getHeaders())
    }

    public async copyFields(from_project: string, to_project: string) {
        return await axios.post(
            `${this.public_url}/copy_fields`,
            {
                from_project,
                to_project,
            }, this.getHeaders())
    }

    public async transferProjectsTo(project_ids: string[], to_user: string) {
        return await axios.post(
            `${this.public_url}/transfer_projects_to`,
            {
                project_ids,
                to_user
            }, this.getHeaders())
    }
}

