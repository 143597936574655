import React from 'react';
import ShareProject from "../../common/shareProject/shareProject";
import ImportFields from "./importFields";

const ProjectSharingPage = () => (
    <div className="m-3">
        <ShareProject />
        <ImportFields />
    </div>
);

export default ProjectSharingPage;
