import React from "react";
import { strings } from "src/common/strings";
import CondensedList from "../../common/condensedList/condensedList";
import UserListItem from "./userListItem";
import type { IProject, IImpersonatedUser } from "src/models/applicationState";

interface UsersProps {
    users: IImpersonatedUser[];
    searchKeyword: string;
    onProjectClick(project: IProject): Promise<void>;
    onProjectDelete(project: IProject): void;
}

const Users = ({ users, searchKeyword, onProjectClick, onProjectDelete }: UsersProps) => {
    return (
        <div className="app-homepage-list bg-lighter-1">
            <CondensedList
                title={strings.homePage.users}
                items={users}
            >
                {(props) => (
                    <UserListItem
                        onProjectClick={onProjectClick}
                        onProjectDelete={onProjectDelete}
                        searchKeyword={searchKeyword}
                        {...props}
                    />
                )}
            </CondensedList>
        </div>
    );
}

export default Users;
