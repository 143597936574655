
import React, { FormEvent } from 'react';
import { Form } from "react-bootstrap"
import { FontIcon } from "@fluentui/react";
import { PrimaryButton } from "@fluentui/react";
import { getPrimaryGreenTheme } from "../../../../common/themes";
import { RinktMLClient } from "../../../../services/rinktService";
import { IApplicationState, IProject, IAuth, IImpersonation } from "../../../../models/applicationState";
import { connect } from "react-redux";
import { toast } from "react-toastify";

function mapStateToProps(state: IApplicationState) {
    return {
        project: state.currentProject,
        recentProjects: state.recentProjects,
        auth: state.auth,
        impersonation: state.impersonation,
    };
}

export interface IImportFieldsProps extends React.Props<ImportFields> {
    project?: IProject;
    recentProjects?: IProject[];
    auth?: IAuth;
    impersonation?: IImpersonation
}

export interface IImportFieldsState {
    project: IProject;
    recentProjects: IProject[];
    selectedProjectId: string;
}

@connect(mapStateToProps)
export default class ImportFields extends React.Component<IImportFieldsProps, IImportFieldsState> {

    public state: IImportFieldsState = {
        project: this.props.project,
        recentProjects: this.props.recentProjects ? this.props.recentProjects : [],
        selectedProjectId: "",
    };

    private importFieldsHandler = async (e: FormEvent) => {
        e.preventDefault();

        try {
            let rinktClient = new RinktMLClient(this.props.auth.idToken, this.props.impersonation);
            await rinktClient.copyFields(this.state.selectedProjectId, this.state.project.id);
            toast.success("Imported fields from " + this.state.project.name)
        } catch (err) {
            toast.error("There was an error processing your request")
        }
    }

    public render() {
        return (
            <>
                <h3 className="mt-5 flex-center">
                    <FontIcon iconName="CloudImportExport" />
                    <span className="px-2">Import fields</span>
                </h3>
                <div className="m-3">
                    <Form onSubmit={this.importFieldsHandler}>
                        <div className="form-fields">
                            <Form.Group id="selectedProject">
                                <Form.Label>Import fields from project:</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={this.state.selectedProjectId}
                                    onChange={e => {
                                        this.setState({ selectedProjectId: e.target.value })
                                    }}>
                                    {this.state.recentProjects.map((p) => {
                                        return (<option value={p.id} key={p.id}>{p.name}</option>)
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <PrimaryButton
                            theme={getPrimaryGreenTheme()}
                            className="mr-2"
                            type="submit">
                            Import
                        </PrimaryButton>
                    </Form>
                </div>
            </>
        );
    }
}
