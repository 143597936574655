import React, { FC, useState, FormEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Card, Alert } from "react-bootstrap"

import { signin, setError } from '../../../../redux/actions/authActions';
import { RootState } from '../../../../redux/store/store';
import "./loginPage.scss";
import logo from "../../../../assets/images/logo.png";

const SignIn: FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { error } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        return () => {
            if (error) {
                dispatch(setError(''));
            }
        }
    }, [error, dispatch]);

    const submitHandler = (e: FormEvent) => {
        e.preventDefault();
        if (error) {
            dispatch(setError(''));
        }
        setLoading(true);
        dispatch(signin({ email, password }, () => setLoading(false)));
    }

    return (
        <div
            className="login d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
        >
            <div className="login-form w-100" style={{ maxWidth: "400px" }}>
                <Card>
                    <Card.Body>
                        <div className="logo">
                            <img src={logo} alt="Rinkt Logo" />
                        </div>
                        <div className="header text-center mb-4">LOGIN TO YOUR ACCOUNT</div>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={submitHandler}>
                            <div className="form-fields">
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" value={email} required onChange={(e) => setEmail(e.currentTarget.value)} placeholder="Enter email" />
                                </Form.Group>
                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" value={password} required onChange={(e) => setPassword(e.currentTarget.value)} placeholder="Enter Password" />
                                </Form.Group>
                            </div>
                            <Button disabled={loading} className="submit-button" type="submit">
                                LOGIN
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}

export default SignIn;
