import React from "react";
import { RinktMLClient } from "../../../../services/rinktService";
import "./filesUploader.scss"
import { Button } from "react-bootstrap";
import { strings } from "../../../../common/strings";
import { IImpersonation } from "../../../../models/applicationState";

export interface IFilesUploaderProps {
    projectId: string;
    authToken: string;
    impersonation: IImpersonation;
    onFileUpload: () => void;
}

export interface IFilesUploaderState {
    selectedFiles: FileList;
    currentFileName: string;
    message: string;
    uploading: boolean;
}

export default class FilesUploader extends React.Component<IFilesUploaderProps, IFilesUploaderState> {
    constructor(props) {
        super(props);

        this.state = {
            selectedFiles: undefined,
            currentFileName: "",
            message: "",
            uploading: false,
        };

        this.upload = this.upload.bind(this);
        this.selectFile = this.selectFile.bind(this);
    }

    selectFile(event) {
        this.setState({
            selectedFiles: event.target.files,
        });
    }

    async upload() {
        const selectedFiles = this.state.selectedFiles;
        for (const selectedFile of Array.from(selectedFiles)) {
            this.setState({
                currentFileName: selectedFile.name,
                uploading: true,
            });
            const rinktClient = new RinktMLClient(this.props.authToken, this.props.impersonation)
            await rinktClient.write(selectedFile.name, selectedFile, selectedFile.size, this.props.projectId)
        }

        this.setState({
            selectedFiles: undefined,
            currentFileName: "",
            uploading: false,
        });

        this.props.onFileUpload()
    }

    render() {
        const {
            selectedFiles,
            message,
            uploading,
        } = this.state;

        return (
            <div>
                <input type="file" multiple disabled={uploading} accept="application/pdf" onChange={this.selectFile} />
                {uploading && <span>{`${strings.uploading}: ${this.state.currentFileName}`}</span>}

                <Button
                    className="uploadButton"
                    disabled={!selectedFiles || uploading}
                    onClick={this.upload}
                >
                    {strings.upload}
                </Button>

                <div className="alert" role="alert">
                    {message}
                </div>
            </div>
        );
    }
}
