import React, { SyntheticEvent, useRef } from "react";
import { FontIcon } from "@fluentui/react";
import { strings } from "src/common/strings";
import { isElectron } from "src/common/hostProcess";
import type { IProject } from "src/models/applicationState";
import FilePicker from "../../common/filePicker/filePicker";
import CondensedList from "../../common/condensedList/condensedList";
import ProjectListItem from "./projectListItem";

interface IRecentProjectsProps {
    projects: IProject[];
    searchKeyword: string;
    onProjectFileUpload(e: SyntheticEvent, file: any): void;
    onProjectFileUploadError(e: SyntheticEvent, error: any): void;
    onItemClick(project: IProject): Promise<void>;
    onItemDelete(project: IProject): void;
}

const RecentProjects = ({
    projects,
    searchKeyword,
    onProjectFileUpload,
    onProjectFileUploadError,
    onItemClick,
    onItemDelete,
}: IRecentProjectsProps) => {
    const filePickerRef = useRef<FilePicker>();
    const filteredProjects = projects?.filter(({ name }) => name.toLowerCase().includes(searchKeyword.toLowerCase()));

    return (
        <div className="app-homepage-list bg-lighter-1">
            {isElectron() && (
                <FilePicker
                    ref={filePickerRef}
                    onChange={onProjectFileUpload}
                    onError={onProjectFileUploadError}
                    accept={[".fott"]}
                >
                    <div
                        className="app-homepage-open-cloud-project"
                        role="button"
                        onClick={() => filePickerRef.current?.upload()}
                    >
                        <FontIcon iconName="System" className="icon" />
                        <span className="title">{strings.homePage.openLocalProject.title}</span>
                    </div>
                </FilePicker>
            )}
            <CondensedList
                title={strings.homePage.recentProjects}
                Component={ProjectListItem}
                items={filteredProjects}
                onClick={onItemClick}
                onDelete={onItemDelete}
            />
        </div>
    );
}

export default RecentProjects;
