import React from "react";
import ShareProject from "../../common/shareProject/shareProject";
import CopyProject from "../../common/copyProject/copyProject";
import Impersonation from "./impersonation";

const SettingsPage = () => (
    <div className="m-3">
        <Impersonation />
        <div className="mt-5">
            <CopyProject multiSelect />
        </div>
        <div className="mt-5">
            <ShareProject multiSelect />
        </div>
    </div>
);

export default SettingsPage;
