import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FontIcon, IconButton } from "@fluentui/react";
import { strings } from "src/common/strings";
import { RinktMLClient } from "src/services/rinktService";
import type { IImpersonatedUser, IImpersonation, IProject } from "src/models/applicationState";
import type { RootState } from "src/redux/store/store";
import CondensedList from "src/react/components/common/condensedList/condensedList";
import useIsMountedRef from "src/react/hooks/useIsMountedRef";
import ProjectListItem from "./projectListItem";
import "src/App.scss";

interface UserListItemProps {
    item: IImpersonatedUser;
    searchKeyword: string;
    onProjectClick(project: IProject): Promise<void>;
    onProjectDelete(project: IProject): void;
}

const UserListItem = ({ item: user, searchKeyword, onProjectClick, onProjectDelete }: UserListItemProps) => {
    const isMountedRef = useIsMountedRef();
    const { idToken } = useSelector((state: RootState) => state.auth);
    const { impersonators } = useSelector((state: RootState) => state.impersonation);
    const [ projects, setProjects ] = useState<IProject[]>(null);
    const [ isExpanded, setIsExpanded ] = useState(false);
    const filteredProjects = projects?.filter((project) => project.name.toLowerCase().includes(searchKeyword.toLowerCase()));
    const shouldDisplay = !searchKeyword || filteredProjects?.length || !projects;

    const handleUserClick = () => {
        setIsExpanded((isExpanded) => !isExpanded);
    };

    useEffect(() => {
        (async() => {
            // Run only once
            if (!projects && idToken && user && isExpanded) {
                const impersonation: IImpersonation = { impersonatedUser: user, impersonators };
                const rinktClient = new RinktMLClient(idToken, impersonation);
                const { recentProjects } = (await rinktClient.getProjects()).data;

                if (isMountedRef.current) {
                    setProjects(recentProjects || []);
                }
            }
        })();
    }, [ idToken, user, projects, impersonators, isExpanded, isMountedRef ]);

    return shouldDisplay && (
        <li className="impersonator-item">
            <div
                className="condensed-list-item"
                onClick={handleUserClick}
                aria-label={`${user.email} impersonator`}
            >
                <FontIcon iconName="OpenFolderHorizontal" />
                <span
                    className="px-2 ms-Fabric"
                    style={{color: "inherit"}}
                >
                    {user.email}
                </span>
                <IconButton
                    className="float-right app-expand-button"
                    title={strings.common.expand}
                >
                    <FontIcon
                        iconName={isExpanded ? "ChevronDown" : "ChevronRight"}
                        className="app-expand-icon"
                    />
                </IconButton>
                {isExpanded && (
                    <div className="my-2">
                        <CondensedList
                            Component={ProjectListItem}
                            items={filteredProjects}
                            onClick={onProjectClick}
                            onDelete={onProjectDelete}
                        />
                    </div>
                )}
            </div>
        </li>
    );
}

export default UserListItem;
